@font-face {
  font-family: "Bebas";
  src:
    local("Bebas"),
    url(./assets/fonts/BebasNeue-Regular.ttf) format("truetype");
}

:root {
  --pp-red: #cd201f;
  --pp-gold: #f2b951;
  --pp-green: #05bf79;

  --bs-alert-border-subtle: #05bf05;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bebas", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: black;
  background-image: url("./assets/images/background.jpg");
  background-position: center 40px;
  background-size: 950px;
  background-repeat: no-repeat;
  color: #f3f3f3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.btn {
  font-family: "Bebas", sans-serif;
  font-size: 18px;
  border-radius: 23px;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active,
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--pp-gold);
  border-color: var(--pp-gold);
  outline: 0;
}

.btn-text.btn {
  border: none;
}

.btn:first-child:active,
.btn-text.btn:focus-visible {
  color: #aeaeae;
  background-color: transparent;
  border: none;
}

.btn-text.btn-danger {
  color: var(--bs-danger);
  background-color: transparent;
}

.btn-text.btn-danger:hover {
  color: var(--bs-danger-bg-subtle);
}

.btn-text.btn-danger:active,
.btn-text.btn-danger:first-child:active,
.btn-text.btn-danger:focus-visible {
  color: var(--bs-danger-bg-subtle);
  background-color: transparent;
}

.btn-primary {
  background-color: #cd201f;
  border-color: #cd201f;
}

.btn-primary:hover {
  background-color: #f2b951;
  color: black;
  border-color: #f2b951;
}

.btn-primary:disabled {
  background-color: white;
  color: black;
  border: 2px solid white;
}

.btn-outline-secondary {
  color: white;
  border-color: #f2b951;
}

.btn-outline-secondary:hover {
  background-color: #f2b951;
  color: black;
  border-color: #f2b951;
}

.text-secondary {
  color: #a0a0a0;
}

.text-subtle {
  color: #aeaeae;
}

.highlight {
  background-color: #f2b951;
  color: black;
}

input[type="tel"].react-international-phone-input {
  width: 100%;
}

.alert.alert-success {
  border-width: 2px;
  border-color: var(--pp-green);
  background-color: white;
}

.alert.alert-danger {
  border-width: 2px;
  border-color: var(--pp-red);
  background-color: white;
}